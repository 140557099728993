<template>
  <div class="rtc-container">
    <div v-if="isHostMode">
      <!-- Host Mode -->
      <el-descriptions style="margin-top: 14px" title="信息" :column="2" :size="size" border>
        <el-descriptions-item label="所属养老院">{{ managerE }}</el-descriptions-item>
        <el-descriptions-item label="管理员电话">{{ managerPhone }}</el-descriptions-item>
      </el-descriptions>

      <p class="label">操作</p>
      <div class="control-container-host">
        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="this.join_state == 0"
            @click="handleJoinRoom">进入房间</el-button>
        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="this.join_state == 1"
            @click="handleLeave">离开房间</el-button>

        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="this.share_state == 0"
            @click="handleStartScreenShare">开始共享屏幕</el-button>
        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="this.share_state == 1"
            @click="handleStopScreenShare">停止共享屏幕</el-button>

        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="this.join_state == 1 && this.invite_state == 0"
            @click="DoInvite">发送邀请链接</el-button>
        <el-button
            class="button"
            type="info"
            size="small"
            v-if="this.join_state == 1 && this.invite_state == 1"
            disabled>链接已发送</el-button>

<!--        <el-popover placement="right" trigger="click" width="auto">-->
<!--          <div v-if="showInviteLink" class="invite-link-container">-->
<!--            <span>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>-->
<!--            <el-input class="invite-input" v-model="inviteLink">-->
<!--              <template slot="prepend">-->
<!--                <el-tooltip-->
<!--                    :visibleArrow="false"-->
<!--                    effect="dark"-->
<!--                    content="已复制！"-->
<!--                    placement="bottom"-->
<!--                    :manual="true"-->
<!--                    v-model="showCopiedTip">-->
<!--                  <span class="invite-btn" @click="DoCopy">-->
<!--                    <el-button>复制链接</el-button>-->
<!--                  </span>-->
<!--                </el-tooltip>-->
<!--              </template>-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <el-button slot="reference" type="primary" size="small" style="margin-left: 2px">手动复制链接</el-button>-->
<!--        </el-popover>-->

        <el-popover placement="right" trigger="click" width="auto">
          <div style="text-align: center;font-size: 20px">请选择查询项目</div>
          <el-button @click="NewGetECG">静态心电</el-button>
          <el-button @click="NewGetECGHolter">动态心电</el-button>
          <el-button @click="NewOtherFile">综合体检报告</el-button>
          <el-button slot="reference" type="primary" size="small" style="margin-left: 2px">查看报告</el-button>
        </el-popover>
      </div>

      <el-dialog title="手写签名" :visible.sync="SignatureVisible" width="600px" :before-close="handleClose">
        <VueSignaturePad width="500px" height="200px" ref="signaturePad" />
        <div>
          <el-button @click="save">保存</el-button>
          <el-button @click="undo">撤销</el-button>
        </div>
      </el-dialog>

      <el-drawer size="20%" title="添加诊疗报告" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
        <div style="margin-left: 5px">诊断结果</div>
        <el-input type="textarea" placeholder="请输入内容" style="margin-left: 5px;width: auto;margin-right: 5px" v-model="conclusion">
        </el-input>
        <div style="margin-left: 5px">治疗建议</div>
        <el-input type="textarea" placeholder="请输入内容" style="margin-left: 5px;width: auto;margin-right: 5px" v-model="suggestion">
        </el-input>
        <div style="margin-left: 5px">电子签名
          <img style="width:100%" :src="Signature" alt="" v-if="Signature">
        </div>
        <el-button style="margin-left: 5px" type="primary" plain icon="el-icon-edit" @click="makeSignature()">添加签名</el-button>
        <el-button style="margin-left: 5px" type="primary" @click="submitResult()">提交报告</el-button>
      </el-drawer>

      <div class="NewContainer">
        <el-col span=6>
          <el-row>
            <div class="remote-container">
              <div
                  v-for="(item) in remoteStreamList"
                  :key="item.getUserId()"
                  :id="item.getUserId()"
                  class="remote-stream-container"
                  @click="fullScreen(item.getUserId())">
              </div>
            </div>
          </el-row>
          <el-row>
            <div v-if="localStream" class="local-stream-container">
              <div id="localStream" class="local-stream-content"></div>
              <div v-if="isPlayingLocalStream" class="local-stream-control">
                <div class="video-control control">
                  <span v-if="!isMutedVideo" @click="unmuteVideo">
                    <i class="el-icon-video-pause" style="background-color: #ffffff"></i>
                  </span>
                  <span v-if="isMutedVideo" @click="muteVideo">
                    <i class="el-icon-video-play" style="background-color: #ffffff"></i>
                  </span>
                </div>
                <div class="audio-control control">
                  <span v-if="!isMutedAudio" @click="muteAudio">
                    <i class="el-icon-turn-off-microphone" style="background-color: #ffffff"></i>
                  </span>
                  <span v-if="isMutedAudio" @click="unmuteAudio">
                    <i class="el-icon-microphone" style="background-color: #ffffff"></i>
                  </span>
                </div>
              </div>
            </div>
          </el-row>
        </el-col>
        <el-col span=18>
          <AcrossTemplate ref="GoAcross"></AcrossTemplate>
        </el-col>
      </div>
    </div>

    <div v-if="isInviteMode">
      <!-- Invite Mode -->
      <div class="control-container-invite">
        <!-- 左侧按钮容器 -->
        <div class="left-control-container">
          <el-button
              class="button"
              type="primary"
              size="small"
              style="text-align: center; font-size: 25px"
              v-if="this.join_state === 0"
              @click="handleJoinRoom" :disabled="isDisabled">进入房间</el-button>
          <el-button
              class="button"
              type="primary"
              size="small"
              style="text-align: center; font-size: 25px"
              v-if="this.join_state === 1"
              @click="handleLeave">离开房间</el-button>
        </div>


      </div>
      <div class="HostContainer" v-if="isInviteMode">
        <div v-if="remoteStreamList.length" class="remote-container">
          <div
              v-for="(item) in remoteStreamList"
              :key="item.getUserId()"
              :id="item.getUserId()"
              class="remote-stream-container"
              @click="fullScreen(item.getUserId())">
            <!-- Single remote stream -->
          </div>
          <div v-if="localStream" class="local-stream-container">
            <div id="localStream" class="local-stream-content"></div>
          </div>
        </div>
        <div v-else class="local-only-container">
          <div v-if="localStream" class="local-stream-container-alone">
            <div id="localStream" class="local-stream-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import Cookies from 'js-cookie';
import screenfull from 'screenfull';
import AcrossTemplate from "@/views/common/across_template.vue";

export default {
  name: 'remote_room',
  components: {
    AcrossTemplate,
  },
  mixins: [rtc, shareRtc],
  props: {
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      diagnosisId: '',
      Signature: '',
      SignatureVisible: false,
      conclusion: '',
      suggestion: '',
      drawer: false,
      direction: 'rtl',
      join_state: 0,
      invite_state: 0,
      public_state: 0,
      share_state: 0,
      managerE: '',
      managerPhone: '',
      UID: Cookies.get('UID'),
      RID: Cookies.get('RID'),
      aID: Cookies.get('aID'),
      PID: Cookies.get('PID'),
      RType: Cookies.get('RType'),
      ptype: Cookies.get('PType'),
      PName: Cookies.get('PName'),
      endTime: '',
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      dialogVisible: false,
      textarea: '',
      search: '',
      valueDate: '',
      selectedDay: '',
      avaDay: '',
      tableData: [{
        testDate: '2022-7-10',
        testKind: '血压',
        testDetail: '12345',
      }, {
        testDate: '2022-7-1',
        testKind: '血糖',
        testDetail: 'qwert',
      }, {
        testDate: '2022-6-21',
        testKind: '血脂',
        testDetail: 'mnbvc',
      },
      ],
      reportList: [],
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isInviteMode() {
      return this.type == 'invite';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
    crossPlatformStatus: {
      get() {
        return this.$store.state.module.crossPlatform
      },
      set(val) {
        this.$store.commit('module/updateCrossPlatform', val)
      }
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  methods: {
    getPhoneNumber(uid) {
      console.log("看看有没有人")
      console.log(uid)
      this.$http.adornHeader({"Cross-Platform-Verification": "Cross-Platform-Access"})
      this.$http({
        url: this.$http.adornUrl('/enterprise/enterpriseuserinfo/app/getEnterprisePhoneByUid?uid=' + uid),
        method: 'POST',
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看回来啥")
          console.log(data)
          this.managerE = data.enterpriseInfo.ename
          this.managerPhone = data.enterpriseInfo.phone
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    getSignature() {
      this.$http({
        url: this.$http.adornUrl('/doctor/doctorinfo/getSignature'),
        method: 'get',
        params: this.$http.adornParams({docId: this.UID})
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log(data)
          this.Signature = data.signature
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    makeSignature() {
      this.SignatureVisible = true
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      console.log("看看签名")
      console.log(data)
      this.Signature = data;
      this.updateSignature()
      this.SignatureVisible = false
    },

    updateSignature() {
      this.$http({
        url: this.$http.adornUrl('/doctor/doctorinfo/updateSignature'),
        method: 'post',
        data: this.$http.adornData({docId: this.UID, signature: this.Signature})
      }).then(({data}) => {
        if (data && data.code === 0) {
          // console.log(data)
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    handleClose(done) {
      this.$confirm('已填写内容未提交，确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    fullScreen(e) {
      const locEl = document.getElementById(e);
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏，安装其他版本试试',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle(locEl)
      this.$message({
        message: '已全屏',
        type: 'success'
      })
    },
    getECGdata() {
      if (this.isHostMode) {
        this.$http({
          url: this.$http.adornUrl("/doctor/personalInfo/staticecg/list"),
          method: "get",
          params: this.$http.adornParams({uid: this.PID}),
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log("看看静态单导列表");
            console.log(data)
            this.reportList = data.staticEcgEntities;
          } else {
            this.$message.error(data.msg);
          }
        });
      }
    },

    NewOtherFile() {
      Cookies.set('Treating', 1, {path: "/"})
      this.$refs.GoAcross.goOtherFile()
    },
    NewGetECG() {
      Cookies.set('Treating', 1, {path: "/"})
      this.$refs.GoAcross.goECG();
    },
    NewGetECGHolter() {
      Cookies.set('Treating', 1, {path: "/"})
      this.$refs.GoAcross.goECGHolter()
    },
    getECG() {
      console.log("看看2023年1月8日");
      console.log(Cookies.get('PID'));
      this.crossPlatformStatus = true
      let uid = Cookies.get('PID')
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: uid,
        }
      })
    },
    getECGHolter() {
      console.log("看看2023年1月8日");
      console.log(Cookies.get('PID'));
      this.crossPlatformStatus = true
      let uid = Cookies.get('PID')
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        }
      })
    },

    handleClick(arr) {
      console.log(arr);
      const routeData = this.$router.resolve({
        name: 'MainHistorySingo2',
        query: {
          "staticRecordId": arr.sid,
          "staticRecordCreatetime": arr.createTime,
          "staticRecordDuration": arr.duration,
          "staticRecordPID": this.PID,
          "RType": this.RType,
        }
      });
      window.open(routeData.href, '_blank');
    },

    submitResult() {
      if (this.RType) {
        console.log("看看数据")
        console.log(this.aID, this.PID, this.UID, this.ptype, this.PName, this.aID)
        let now = new Date();
        let nowDay = now.getDate();
        let nowMonth = now.getMonth() + 1;
        let nowYear = now.getFullYear();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes();
        this.endTime = nowYear + "-" + nowMonth + "-" + nowDay + " " + hh + ":" + mf;
        this.$http({
          url: this.$http.adornUrl("/doctor/recorddiagnosis/save"),
          method: 'post',
          data: this.$http.adornData({
            userId: this.PID,
            docId: this.UID,
            userType: this.ptype,
            uname: this.PName,
            orderId: this.aID,
            symptoms: this.$route.query.Symptoms,
            orderTime: this.$route.query.aTime,
            diagnosisTime: this.endTime,
            diagnosisNotes: this.suggestion,
            diagnosisConclusion: this.conclusion,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log("看看收到的东西")
            console.log(data)
            this.diagnosisId = data.diagnosisId
            this.$notify({
              title: '成功',
              message: '诊疗记录已保存',
              type: 'success'
            })
          } else {
            this.$message.error(data.msg)
          }
        });
        setTimeout(() => {
          this.pushReport();
        }, 1000);
      }
    },

    pushReport() {
      console.log("看看主诉")
      console.log(this.$route.query.Symptoms)
      this.$http({
        url: this.$http.adornUrl('/doctor/doctorVip/monthlyReport'),
        method: 'get',
        params: this.$http.adornParams({
          uid: this.PID,
          url: 'https://www.xin-gou.com/test/#/across/TreatReportAcross?diagnosisId=' + this.diagnosisId + '&docId=' + this.UID
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '成功将诊疗报告推送至微信公众号！',
            type: 'success'
          });
        } else {
          this.$message({
            message: '未关注公众号，请微信搜索【心狗健康】并关注',
            type: 'warning'
          });
        }
      })
      this.drawer = false
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const {sdkAppId, secretKey, roomId} = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },

    DoInvite() {
      this.$http({
        url: this.$http.adornUrl("/doctor/recordorder/createLink"),
        method: 'post',
        data: this.$http.adornData({
          orderId: this.aID,
          inviteLink: this.inviteLink,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看收到的东西")
          console.log(data)
          this.invite_state = 1
          this.$notify({
            title: '成功',
            message: '发送邀请成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    DoCopy() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },

    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
      this.$http({
        url: this.$http.adornUrl("/doctor/recordorder/createLink"),
        method: 'post',
        data: this.$http.adornData({
          orderId: this.aID,
          inviteLink: this.inviteLink,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看收到的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '发送邀请成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    ReOpen: function () {
      if (Cookies.get('Opened') == 1) {
        console.log("ok兄弟们")
        let that = this
        setTimeout(function () {
          that.handleJoinRoom()
        }, 1000)
      }
    },
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert("请输入 sdkAppId 和 secretKey");
          return;
        }
        if (!this.userId || !this.roomId) {
          alert("请输入 userId 和 roomId");
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert("请重新获取邀请链接！");
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
      this.join_state = 1;
    },

    async handlePublish() {
      await this.publish();
      this.public_state = 1;
    },

    async handleUnpublish() {
      await this.unPublish();
      this.public_state = 0;
    },

    async handleLeave() {
      if (this.isHostMode) {
        this.drawer = true;
      }
      await this.leave();
      this.join_state = 0;
    },

    ReShare: function () {
      if (Cookies.get('Shared') == 1) {
        console.log("全体目光向我看齐")
        let that = this
        setTimeout(function () {
          that.handleStartScreenShare()
        }, 1000)
      }
    },

    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert("请输入 sdkAppId 和 secretKey");
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
      this.share_state = 1;
    },

    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
      this.share_state = 0;
    },

    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });

      const {scrollHeight} = 350;
      console.log("看看高度")
      console.log(scrollHeight)
    },

    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      const {scrollHeight} = 350;
      console.log("看看高度")
      console.log(scrollHeight)
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
  },
  created() {
    this.sdkAppId = 1400679197;
    this.secretKey = 'eb0260efee2dd0afe1351537ecec24caedc71fc0ff1a83b66dbb62d5db15a32c';
  },
  mounted() {
    this.ReOpen();
    this.ReShare();
    this.getSignature();
    this.getPhoneNumber(this.PID)
  },
  beforeDestroy() {
    Cookies.set('Treating', 0, {path: "/"})
  },
};
</script>

<style lang="scss" scoped>
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container-invite {
    width: 90%;
    position: relative;
    margin-bottom: 1px;
    height: 40px;

    .left-control-container {
      position: absolute;
      left: 15px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .right-control-container {
      position: absolute;
      right: 5px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .control-container-host {
    text-align: left;
    margin-bottom: 10px;

    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }

    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;

    .invite-input {
      margin-top: 10px;
    }

    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .NewContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;

      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }

      .log-state {
        display: inline-block;
        margin-right: 6px;
      }

      > div {
        font-size: 12px;
      }
    }

    .local-stream-container {
      width: 100%;
      height: 100%;
      position: relative;
      margin-left: 0;
      flex-shrink: 0;

      .local-stream-content {
        width: 100%;
        height: 100%;
      }

      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;

        .control {
          margin-left: 10px;
        }

        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;

      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }

      .log-state {
        display: inline-block;
        margin-right: 6px;
      }

      > div {
        font-size: 12px;
      }
    }

    .local-stream-container {
      width: 100%;
      height: 40%;
      position: relative;
      margin-left: 0;
      flex-shrink: 0;

      .local-stream-content {
        width: 100%;
        height: 100%;
      }

      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;

        .control {
          margin-left: 10px;
        }

        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;

    .log-container {
      margin-right: 0;
    }

    .local-stream-container {
      width: 100%;
      height: 100%;
      margin-top: 10px;
    }
  }

  .remote-container {
    width: 100%;
    margin-top: 0;
    position: relative;

    .remote-stream-container {
      width: 100%;
      height: 100%;

      .remote-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;

        .control {
          margin-left: 10px;
        }

        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .HostContainer {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .remote-container {
      width: 90%;
      height: auto;
      margin: 0 auto;
      position: relative;

      .remote-stream-container {
        width: 100%;
        height: 100%;
        position: relative;

        .remote-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }

      .local-stream-container {
        width: 30%;
        height: auto;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;

        .local-stream-content {
          width: 100%;
          height: 100%;
        }

        .local-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .local-only-container {
      width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;

      .local-stream-container-alone {
        width: 100%;

        .local-stream-content {
          width: 100%;
          height: 100%;
        }

        .local-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
